import { ApiError } from '../../errors';
import { CCApiService } from './api-service';
import { Application, UIConfig, User } from './interfaces';

export class UsersService {
  static ApiService: CCApiService;
  static usersApi: string;
  static apiBasePath: string;
  static customerId: string;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customerId = customerId;
    this.usersApi = `${config.apiBasePath}/customers/${this.customerId}/users`;
  }

  static async getUsers(): Promise<User[]> {
    return this.ApiService.performFetch(this.usersApi).then(users => users.content);
  }

  static async getUsersWithAppRoles(app: Application): Promise<User[]> {
    return this.ApiService.performFetch(
      ` ${this.apiBasePath}/customers/${app.customerId}/solutions/${app.id}/users`
    ).then(result => result);
  }

  static async getUsersWithoutAppRoles(app: Application): Promise<User[]> {
    return this.ApiService.performFetch(
      `${this.apiBasePath}/customers/${app.customerId}/solutions/${app.id}/users/unassigned`
    ).then(result => result);
  }

  static async addUser(user: User): Promise<void> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(user),
    };
    return this.ApiService.performFetch(this.usersApi, fetchOptions).catch(this._mapApiErrorCodeToMessage);
  }

  static async patchUser(user: User): Promise<void> {
    const url = `${this.usersApi}/${user.email}`;
    const fetchOptions = {
      method: 'PATCH',
      body: JSON.stringify(user),
    };
    return this.ApiService.performFetch(url, fetchOptions).catch(this._mapApiErrorCodeToMessage);
  }

  static async removeUser(user: User): Promise<void> {
    const url = `${this.usersApi}/${user.email}`;
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(url, fetchOptions);
  }

  /* tslint:disable-next-line */
  static async getUser(user: User): Promise<void> {
    const url = `${this.usersApi}/${user.email}`;
    const fetchOptions = {
      method: 'GET',
    };
    return this.ApiService.performFetch(url, fetchOptions);
  }

  static _mapApiErrorCodeToMessage(error) {
    if (error instanceof ApiError && error.message === 'E0000038') {
      error.message = 'The user cannot be added. Please contact customer support.';
    }
    throw error;
  }
}
